@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: Jura;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(/fonts/Jura-Regular.woff2) format("woff2")
}

@font-face {
  font-family: Conthrax;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(/fonts/Conthrax-Regular.woff2) format("woff2")
}

@font-face {
  font-family: Conthrax;
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(/fonts/Conthrax-SemiBold.woff2) format("woff2")
}

@font-face {
  font-family: Conthrax;
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(/fonts/Conthrax-Light.woff2) format("woff2")
}

:root {
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

body, html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  min-height: 100vh; /* Ensures the gradient covers the whole viewport */
  background: linear-gradient(to right, #ff115e, #ff9566); /* Your brand gradient */
  color: #ffffff; /* Ensuring text is visible over the gradient; adjust as needed */
}

.font-conthrax {
  font-family: Conthrax, sans-serif;
}

.font-jura {
  font-family: Jura, sans-serif;
}

.animated-link{
  @apply relative pb-1 outline-none overflow-hidden text-white transition-all duration-300 ease-in-out hover:text-primary before:content-[''] before:w-full before:absolute before:left-[-100%] before:h-0.5 before:bottom-0 before:bg-primary before:transition-all before:duration-300 before:ease-in-out before:z-[-1] hover:before:left-0;
}

.animated-link-2{
  @apply relative pb-1 outline-none overflow-hidden text-white transition-all duration-200 ease-in-out hover:text-primary hover:brightness-110;
}


.button {
  @apply inline-block text-center text-black transition-all duration-150 ease-in-out bg-primary border border-transparent active:scale-95;
  box-shadow: 0px 0px 2px theme('colors.primary');
}

.button:hover{
  box-shadow: 0px 0px 14px theme('colors.primary');
}

.text-input-box{
  box-shadow: 0px 0px 2px theme('colors.primary');
  @apply w-full px-3 py-2 bg-transparent outline-none border-2 hover:brightness-105 focus:brightness-105 transition-all duration-300 ease-in-out border-primary
}

.text-input-box:focus, .text-input-box:hover{
  box-shadow: 0px 0px 14px theme('colors.primary')
}

.animated-para{
  animation: moveUp 1s ease-in-out;
}

@keyframes moveUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.heading{
  position: relative;
  width: fit-content;
  color: transparent;
  -webkit-text-stroke: 2px theme('colors.primary');
  animation: animate 2s linear infinite;
}


@keyframes animate {
  0%{
    scale: 1;
    text-shadow: 0px 0px 2px theme('colors.primary');
  }
  50%{
    scale: 1.02;
    text-shadow: 0px 0px 30px theme('colors.primary');
  }
  100%{
    scale: 1;
    text-shadow: 0px 0px 2px theme('colors.primary');
  }
}

.glitch {
  text-transform: uppercase;
  position: relative;
  text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
    0.025em 0.04em 0 #fffc00;
  animation: glitch 725ms infinite;
}

.glitch span {
  position: absolute;
  top: 0;
  left: 0;
}

.glitch span:first-child {
  animation: glitch 500ms infinite;
  clip-path: polygon(0 0, 100% 0, 100% 35%, 0 35%);
  transform: translate(-0.04em, -0.03em);
  opacity: 0.75;
}

.glitch span:last-child {
  animation: glitch 375ms infinite;
  clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
  transform: translate(0.04em, 0.03em);
  opacity: 0.75;
}

@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
      0.025em 0.04em 0 #fffc00;
  }
  15% {
    text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
      0.025em 0.04em 0 #fffc00;
  }
  16% {
    text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
      -0.05em -0.05em 0 #fffc00;
  }
  49% {
    text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
      -0.05em -0.05em 0 #fffc00;
  }
  50% {
    text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
      0 -0.04em 0 #fffc00;
  }
  99% {
    text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
      0 -0.04em 0 #fffc00;
  }
  100% {
    text-shadow: -0.05em 0 0 #00fffc, -0.025em -0.04em 0 #fc00ff,
      -0.04em -0.025em 0 #fffc00;
  }
}

.animation {
  height:50px;
  overflow:hidden;
  margin-left: 1rem;
}

.animation > div > div {
  margin-bottom: 2.81rem;
  display:inline-block;
  width: 100%;
}

.animation div:first-child {
  animation: text-animation 8s infinite;
}

.first div {
  background-color:#20a7d8;
}
.second div {
  background-color:#CD921E;
}
.third div {
  background-color:#c10528;
}

@keyframes text-animation {
  0% {margin-top: 0;}
  10% {margin-top: 0;}
  20% {margin-top: -33%;}
  30% {margin-top: -33%;}
  40% {margin-top: -66%;}
  60% {margin-top: -66%;}
  70% {margin-top: -33%;}
  80% {margin-top: -33%;}
  90% {margin-top: 0;}
  100% {margin-top: 0;}
}

.animated-logo{
  filter: grayscale(1) brightness(4);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin: auto;
}

.animated-logo:hover{
  filter: grayscale(0) brightness(1);
  scale: 1.1;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #282828;
}

::-webkit-scrollbar-thumb {
  background: theme('colors.primary');
}

::selection {
  background: theme('colors.primary');
  color: rgb(255, 255, 255);
}

::-moz-selection {
  background: theme('colors.primary');
  color: rgb(255, 255, 255);
}

.video-container {
  width: 100%;
  height: 700px;  /* Fixed height for consistency */
  position: relative;
  overflow: hidden;
  margin-top: 100px; /* You can adjust this to push the video up or down as needed */
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Fills the container, cropping if necessary */
}

/* Smaller padding and margin for specific sections */
.sm-padding {
  padding: 0.5rem;  /* Smaller than p-6 which is typically 1.5rem */
}

.sm-margin-top {
  margin-top: 0.5rem;  /* Smaller than mt-6 which is typically 1.5rem */
}

.number-highlight {
  font-weight: bold;  /* Makes the text bold */
  text-decoration: underline;  /* Adds an underline */
  color: var(--primary-color);  /* primary color variable set */
}


/* Loader CSS */
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

  .animated-logo {
    filter: grayscale(100%);
    transition: filter 0.3s ease-in-out;
  }
  
  .animated-logo:hover {
    filter: grayscale(0%);
  }
  